import { Alert, Button, Snackbar } from '@mui/material';
import React, { useRef, useEffect } from 'react';
import SignaturePad from 'signature_pad';
import CloseIcon from '@mui/icons-material/Close';
import { FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import InputCheckBoxField from '../../registration/Inputs/InputCheckBoxField';

const SignaturePadComponent = ({ setSignatureImage, handleClose, applicant_fname, applicant_lname }) => {
  const canvasRef = useRef(null);
  const signaturePadRef = useRef(null);
  const [SignatureErr, setSignatureErr] = React.useState(false);
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      signaturePadRef.current = new SignaturePad(canvas);

      // Resize the canvas to fill the container
      resizeCanvas();
      window.addEventListener('resize', resizeCanvas);

      return () => {
        window.removeEventListener('resize', resizeCanvas);
      };
    }
  }, []);

  const resizeCanvas = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext('2d')?.scale(ratio, ratio);
    }
  };

  const handleClear = () => {
    signaturePadRef.current?.clear();
    setSignatureErr(false);
  };

  const handleSave = () => {
    if (signaturePadRef.current?.isEmpty()) {
      // notify('Please provide a signature first.',{type:'warning',anchorOrigin: { vertical: 'top', horizontal: 'right' }});
      setSignatureErr(true);
    } else {
      const dataURL = signaturePadRef.current.toDataURL();
      // console.log(dataURL); // You can send this dataURL to your server or save it as an image
      //  alert('Signature saved!');
      setSignatureImage(dataURL);
      handleClose();
    }
  };
  const yupSchema = Yup.object().shape({
    i_confirm: Yup.bool().oneOf([true], 'You must confirm this').required('I confirm is required'),

  });
  const form = useForm({ mode: "all", resolver: yupResolver(yupSchema) });
  const handleSaveClick = (event) => {
    event.preventDefault();  // Prevent default button click behavior
    form.handleSubmit(handleSave)();  // Explicitly submit the form
};
  return (
    <div>
      <FormProvider {...form}>
        <form className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <div style={{ border: '1px solid #000', padding: '10px' }}>
              <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 mt-2">
            <div className="apply-check">
              <div className="cus-check red-check">
                <InputCheckBoxField
                  notRequired={false}
                  checked={form.watch("i_confirm")}
                  label={`By signing this form, I acknowledge that I am "${applicant_fname} ${applicant_lname}" or that I am authorized to sign on behalf of "${applicant_fname} ${applicant_lname}".`}
                  register={form.register("i_confirm")}
                  errors={form.formState.errors.i_confirm}
                />
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-end mt-3'>
            <Button variant='outlined' onClick={handleClear} >Clear</Button>
            <Button variant='contained' style={{ marginLeft: 4 }} type='button' onClick={handleSaveClick}>Save</Button>
          </div>
        </form>
      </FormProvider>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{ width: '100%', maxWidth: '600px' }}
        open={SignatureErr}
        key={1}
      >
        <Alert
          onClick={()=> setSignatureErr(false)}
          severity="error"
        >
          Please provide a signature. <CloseIcon />
        </Alert>
      </Snackbar>
    </div>
  );
};


export default SignaturePadComponent;
