import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux'
import store, { persister } from './store';
import { PersistGate } from 'redux-persist/integration/react'
import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'bootstrap/dist/css/bootstrap.css';

import 'react-toastify/dist/ReactToastify.css';
import "./index.css";
import { createTheme, ThemeProvider } from '@mui/material/styles';

// import './assets/css/index.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2DC3E8', // Replace with your desired primary color
    },
  },
  typography: {
    button: {
      fontFamily: 'Open Sans', // Set the font family for buttons
      textTransform:'none',
      fontWeight: 'bold',
    },
  }, 
   components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          color: '#ffffff', // Set the text color for contained buttons to white
        },
      },
    },
  },
});


ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
