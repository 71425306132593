// create a from where user can sign the document with there full name 
// form should contains option to choose the sign style, input box to type both required field 
// a canvas to show the signature form type name
// and a button to save the signature 
import React, { useState, useRef, useEffect } from 'react';
import html2canvas from 'html2canvas';
import { Button } from '@mui/material';
import InputField from '../../registration/Inputs/InputField';
import { FormProvider, useForm } from 'react-hook-form';
import SelectField from '../../registration/Inputs/SelectField';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { debounce } from 'lodash';
import InputCheckBoxField from '../../registration/Inputs/InputCheckBoxField';

const SignatureForm = ({ applicant_fname, applicant_lname, setSignatureImage, handleClose }) => {
    // const [fullName, setFullName] = useState('');
    // const [signStyle, setSignStyle] = useState('cursive');
    const canvasRef = useRef(null);
    const signatureRef = useRef(null);

    const validateFullNameMatches = (applicantFname, applicantLname, fullName) => {
        const normalizedFullName = fullName.trim().toLowerCase().replace(/\s+/g, '');
        const normalizedFname = applicantFname.trim().toLowerCase().replace(/\s+/g, '');
        const normalizedLname = applicantLname.trim().toLowerCase().replace(/\s+/g, '');

        return normalizedFullName.includes(normalizedFname) || normalizedFullName.includes(normalizedLname);
    };

    const form = useForm({
        defaultValues: {
            fullName: '',
            signStyle: { value: '', label: '' },
        },
        resolver: yupResolver(Yup.object().shape({
            fullName: Yup.string().required('Name is required')
                .test('is-valid-fullname', 'Name must contain and match the applicant’s first or last name.', function (value) {
                    return validateFullNameMatches(applicant_fname, applicant_lname, value);
                }),
            signStyle: Yup.object(
                { value: Yup.string().required('Sign Style is required'), label: Yup.string() }
            ).required('Sign Style is required'),
            i_confirm: Yup.bool().oneOf([true], 'You must confirm this').required('I confirm is required'),
        })),
        mode: "all",
    });
    const DEBOUNCE_DELAY = 100; // Adjust the delay as needed

    const generateSignatureDebounced = debounce(() => {
        generateSignature();
    }, DEBOUNCE_DELAY);

    useEffect(() => {
        if (form.watch('signStyle')?.value && form.watch('fullName')) {
            const ctx = canvasRef.current.getContext('2d');
            ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            generateSignatureDebounced();
        }

        // Cleanup function to cancel the debounced call if the component unmounts
        return () => {
            generateSignatureDebounced.cancel();
        };
    }, [form.watch('signStyle'), form.watch('fullName')]);

    const cropWhitespace = (canvas) => {
        const ctx = canvas.getContext('2d');
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        let top = null, bottom = null, left = null, right = null;

        for (let y = 0; y < canvas.height; y++) {
            for (let x = 0; x < canvas.width; x++) {
                const index = (y * canvas.width + x) * 4;
                const alpha = data[index + 3];

                if (alpha !== 0) { // If not fully transparent
                    if (top === null) top = y;
                    if (bottom === null || y > bottom) bottom = y;
                    if (left === null || x < left) left = x;
                    if (right === null || x > right) right = x;
                }
            }
        }

        // If no non-transparent pixels are found, return the original canvas
        if (top === null || bottom === null || left === null || right === null) {
            return canvas;
        }

        const croppedWidth = right - left + 1;
        const croppedHeight = bottom - top + 1;

        const croppedCanvas = document.createElement('canvas');
        croppedCanvas.width = croppedWidth;
        croppedCanvas.height = croppedHeight;
        const croppedCtx = croppedCanvas.getContext('2d');

        croppedCtx.drawImage(canvas, left, top, croppedWidth, croppedHeight, 0, 0, croppedWidth, croppedHeight);

        return croppedCanvas;
    };

    const generateSignature = async () => {
        const signatureDiv = signatureRef.current;
        const canvas = await html2canvas(signatureDiv);
        const ctx = canvasRef.current.getContext('2d');
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        const canvasWidth = canvasRef.current.width;
        const canvasHeight = canvasRef.current.height;
        const imageWidth = canvas.width;
        const imageHeight = canvas.height;
        const x = (canvasWidth - imageWidth) / 2;
        const y = (canvasHeight - imageHeight) / 2;
        ctx.drawImage(canvas, x, y);
    };


    const saveSignature = (value) => {
        // alert('Signature saved successfully!')
        // const canvas = canvasRef.current;
        // const signatureData = canvas.toDataURL();
        const signatureDiv = signatureRef.current;
        html2canvas(signatureDiv).then((canvas) => {
            const croppedCanvas = cropWhitespace(canvas);
            const signatureData = croppedCanvas.toDataURL();
            console.log('Signature Data:', signatureData);
            setSignatureImage(signatureData);
            handleClose();
        });
        // Handle signature data here
        // console.log('Full Name:', form.watch('fullName'));
        // console.log('Signature Style:', form.watch('signStyle'))
        // console.log('Signature Data:', signatureData);
        // setSignatureImage(signatureData);
        // handleClose();
    };
    const handleClear = () => {
        form.reset({ fullName: '', signStyle: { value: '', label: '' } });
        canvasRef.current.getContext('2d').clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }
    const handleSaveClick = (event) => {
        event.preventDefault();  // Prevent default button click behavior
        form.handleSubmit(saveSignature)();  // Explicitly submit the form
    };
    return (
        <FormProvider {...form}>
            {/*  onSubmit={form.handleSubmit(saveSignature)} */}
            <form id='signatureForm'>
                <div className='row'>
                    <div className="col-sm-12 col-md-12">
                        <div className="form-group cus-input-group mr-b20">
                            <InputField
                                style={styles.input}
                                register={form.register('fullName')}
                                label={'Full Name'}
                                isNotRequired={false}
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12">
                        <div className="form-group cus-input-group mr-b20">
                            <SelectField
                                label={'Signature Style'}
                                register={form.register('signStyle')}
                                notRequired={false}
                                options={[
                                    { value: 'Allura', label: 'Allura' },
                                    { value: 'Alex Brush', label: 'Alex Brush' },
                                    { value: 'Dancing Script', label: 'Dancing Script' },
                                    { value: 'Great Vibes', label: 'Great Vibes' },
                                    { value: 'Herr Von Muellerhoff', label: 'Herr Von Muellerhoff' },
                                    { value: 'Pacifico', label: 'Pacifico' },
                                    { value: 'Parrina', label: 'Parrina' },
                                    { value: 'Rochester', label: 'Rochester' },
                                    { value: 'Satisfy', label: 'Satisfy' },
                                    { value: 'Yellowtail', label: 'Yellowtail' },
                                ]}
                            />
                        </div>
                    </div>
                    {form.watch('signStyle')?.value && form.watch('fullName') && <div className="col-sm-12 col-md-12">
                        <div className="form-group cus-input-group mr-b20" style={{ overflowX: 'scroll' }}>
                            <label style={styles.label} htmlFor="signatureCanvas">Signature</label>
                            <div ref={signatureRef} style={{ ...styles.signatureDiv, fontFamily: form.watch('signStyle')?.value }}>
                                {form.watch('fullName') || 'Generating Signature'}
                            </div>
                            <canvas
                                id="signatureCanvas"
                                ref={canvasRef}
                                width="380"
                                height="200"
                                style={styles.canvas}
                            ></canvas>
                        </div></div>}
                    <div className="col-sm-12 col-md-12 col-lg-12 mt-2">
                        <div className="apply-check">
                            <div className="cus-check red-check">
                                <InputCheckBoxField
                                    notRequired={false}
                                    checked={form.watch("i_confirm")}
                                    label={`By signing this form, I acknowledge that I am "${applicant_fname} ${applicant_lname}" or that I am authorized to sign on behalf of "${applicant_fname} ${applicant_lname}".`}
                                    register={form.register("i_confirm")}
                                    errors={form.formState.errors.i_confirm}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-end mt-3'>
                        <Button variant='outlined' onClick={handleClear} >Clear</Button>
                        <Button onClick={handleSaveClick} variant='contained' style={{ marginLeft: 4 }}>Save</Button>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};

const styles = {
    form: {
        background: 'white',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Arial, sans-serif',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    canvas: {
        border: '1px solid #000',
        marginBottom: '16px',
    },
   
    signatureDiv: {
        position: 'absolute',
        top: '-9999px',
        left: '-9999px',
        whiteSpace: 'nowrap',
        fontSize: '48px',
    },
};


export default SignatureForm;