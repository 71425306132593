import React, { useState, useEffect } from "react";
import ESignature from "../../../common/eSignature/eSignature";
import StripeButton from "../../../common/stripeButton/stripeButton";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { get, useController, useFormContext } from "react-hook-form";
import InputField from "../../../registration/Inputs/InputField";

import { useGetIdentificationDataQuery } from "../../../../services/identificationApi";
import { useSelector } from "react-redux";
import SelectField from "../../../registration/Inputs/SelectField";
import { pk } from "../../../../config";
import { Alert, IconButton, Snackbar, Typography } from "@mui/material";


import { Close } from "@material-ui/icons";
const PUBLIC_KEY = pk;

const stripeTestPromise = loadStripe(PUBLIC_KEY);

const PaymentSignature = (props) => {
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const getIdentificationData = useGetIdentificationDataQuery(trn_id);
  const [identificationData, setIdentificationData] = useState(
    getIdentificationData?.data
  );
  const [signatureFlag, setSignatureFlag] = useState(false);
  const [signatureImage, setSignatureImage] = useState("");
  const [isEmptySignature, setIsEmptySignature] = useState(false);

  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    control,
    trigger,

    formState: { errors },
  } = useFormContext();

  let addressData = {
    billingAddress: watch("billingAddress"),
    city: watch("city"),
    state: watch("state"),
    zipCode: watch("zipCode"),
    name: identificationData?.identification_data.first_name + " " + identificationData?.identification_data.last_name,
    email: identificationData?.applicant.Email,
    phone: identificationData?.applicant.mobile[0].number,
    trn_id: trn_id,
    file: signatureImage,
    document_id: "8",
  };

  const watchApplicantBillingAddress = watch("copybillingaddress");
  const watchApplicantName = watch("copyapplicantname");

  useEffect(() => {
    if (watchApplicantBillingAddress) {
      setValue(
        "billingAddress",
        getIdentificationData?.data?.address[0]?.street_address,
        { shouldValidate: true }
      );
      setValue("city", getIdentificationData?.data?.address[0]?.city, {
        shouldValidate: true,
      });
      setValue("state", getIdentificationData?.data?.address[0]?.state, {
        shouldValidate: true,
      });
      setValue("zipCode", getIdentificationData?.data?.address[0]?.zipcode, {
        shouldValidate: true,
      });
    } else {
      setValue("billingAddress", "");
      setValue("city", "");
      setValue("state", "");
      setValue("zipCode", "");
    }
  }, [
    watchApplicantBillingAddress,
    setValue,
    identificationData?.address,
    getIdentificationData?.data?.address,
  ]);

  useEffect(() => {
    if (watchApplicantName) {
      setValue(
        "cname",
        getIdentificationData?.data?.applicant?.first_name +
        " " +
        getIdentificationData?.data?.applicant?.last_name,
        { shouldValidate: true }
      );
    } else {
      setValue("cname", "", { shouldValidate: true });
    }
  }, [
    watchApplicantName,
    identificationData?.applicant?.first_name,
    identificationData?.applicant?.last_name,
    setValue,
    identificationData,
    getIdentificationData,
  ]);

  return (
    <div className="site-cus-tab" id="ps">
      <div
        id="psCollapse"
        className={`"ps-wrap white-box  pd20"`}
      >
        <div className="ps-body">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="blue-box pd15 mr-b50">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="row">
                      <div className="col-sm-12 col-md-8 col-lg-8 ">
                        <div className="blue-box pd30 mr-b50">
                          <div className="cus-check mr-b15">
                            <input
                              type="checkbox"
                              id="copyapplicantname"
                              name="copyapplicantname"
                              {...register("copyapplicantname")}
                            />
                            <label
                              htmlFor="copyapplicantname"
                              className="mr-b0 mx-auto align-items-center"
                            >
                              <span className="mr-r10"></span>
                              Copy Applicant Name
                            </label>
                          </div>
                          <div style={{ height: "10px" }} />
                          <InputField
                            label="Enter Card Holder Name"
                            register={register("cname")}
                          />
                          <div className="mb-2" />
                          <label
                            htmlFor={"cardNumber"}
                            className={`form-label`}
                          >
                            Enter Card Number <span className="red">*</span>
                          </label>
                          <Elements
                            stripe={stripeTestPromise}
                            className="stripe-btn"
                          >
                            <StripeButton
                              applicationFee={props?.applicantFee}
                              signatureImage={signatureImage}
                              addressData={addressData}
                              callSubmit={props.callSubmit}
                              setIsEmptySignature={setIsEmptySignature}
                            />
                          </Elements>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 ">
                    <div className="ad-b-check mr-b30">
                      <div className="cus-check mr-b15">
                        <input
                          type="checkbox"
                          id="copybillingaddress"
                          name="copybillingaddress"
                          {...register("copybillingaddress")}
                        />
                        <label
                          htmlFor="copybillingaddress"
                          className="mr-b0 mx-auto d-flex align-items-center"
                        >
                          <span className="mr-r10"></span>
                          Use current address for billing address
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="w-100"></div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <InputField
                      label="Billing Address"
                      register={register("billingAddress")}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <InputField label="City" register={register("city")} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    {/* <InputField label="State" register={register("state")} /> */}
                    <SelectField
                      options={getIdentificationData?.data?.states?.map(
                        (item, index) => {
                          return {
                            value: item.ID || index,
                            label: item.StateShortCode + " - " + item.StateName,
                          };
                        }
                      )}
                      label="Select State"
                      register={register("state")}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mt-2">
                    <InputField
                      label="Zip Code"
                      type={"tel"}
                      maxLength={10}
                      minLength={5}
                      register={register("zipCode")}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12 px-3 p-2">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 py-2" style={{ display: 'flex',flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                  {signatureImage && <img
                    src={signatureImage}
                    alt="signaturePadImage"
                  />}
                  <div className="d-flex">
                    <h5 className="  ">
                      E-Signature: <span className="red">*</span>
                    </h5>
                    <span className="ms-2">
                      {identificationData?.applicant?.first_name}{" "}
                      {identificationData?.applicant?.last_name}
                    </span>

                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 p-2"  style={{ display: 'flex',flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                {(<>
                    <ESignature
                  signatureFlag
                    setSignatureImage={setSignatureImage}
                    applicantFirstName={
                      identificationData?.applicant?.first_name
                    }
                    applicantLastName={
                      identificationData?.applicant?.last_name
                    }
                  />
                    <Snackbar
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={isEmptySignature}
                      autoHideDuration={6000}
                      key={1}
                      sx={{ width: '100%', maxWidth: '600px' }}
                    >
                      <Alert
                        
                        severity="error"

                      >
                        {" "}
                        Please Sign the Application. <Close />
                      </Alert>
                    </Snackbar>
                  </>
                  )}
                </div>
              </div>
            </div>

            {/* <div className="col-sm-12 col-md-6 col-lg-6 ">
              <div className="t-btn text-right ">
                {signatureFlag && (
                  
                )}
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSignature;
