import React, { useRef, useState, useEffect, createRef } from "react";
import { Modal, Box, Typography, Tabs, Tab, IconButton } from '@mui/material';
import SignaturePadComponent from "./SignaturePadComponent";
import SignatureFromComponent from "./SignatureFromComponent";
import CloseIcon from '@mui/icons-material/Close';
import { FiEdit2 } from "react-icons/fi";
import { Button } from "@mui/material";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function ModalWithTabs({ applicant_lname, applicant_fname, setSignatureImage }) {
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Button
        variant="outlined"
        startIcon={<FiEdit2 />}
        onClick={() => {
          handleOpen();
        }}
      >
        Click to e-Sign
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              E-Signature 
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab label="Type Name" />
              <Tab label="Draw Signature" />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <SignatureFromComponent handleClose={handleClose} setSignatureImage={setSignatureImage} applicant_fname={applicant_fname} applicant_lname={applicant_lname} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <SignaturePadComponent handleClose={handleClose} setSignatureImage={setSignatureImage} applicant_fname={applicant_fname} applicant_lname={applicant_lname}  />
          </TabPanel>
        </Box>
      </Modal>
    </div>
  );
}



const ESignature = ({ setSignatureImage, applicantFirstName, applicantLastName }) => {
  return <ModalWithTabs setSignatureImage={setSignatureImage} applicant_fname={applicantFirstName} applicant_lname={applicantLastName} />
};

export default ESignature;
